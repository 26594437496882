export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "services_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
        "services_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R4 Enterprises Corporation provides a diverse array of recycling, environmental and remediation services to industrial, commercial, and government clients. Project experiences include U.S. Government facilities and bases, transport maintenance facilities, automotive facilities, hospitals, industrial and manufacturing facilities, and numerous other facilities. Our diversified staff includes environmental and chemical engineers, environmental scientists and technicians, and qualify and trained labors. Our company provides a healthy and safely working environment to our employees and clients."])}
      },
      "es": {
        "services_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
        "services_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R4 Enterprises Corporation brinda una amplia gama de servicios de reciclaje, ambientales y de remediación a clientes industriales, comerciales y gubernamentales. Las experiencias de proyectos incluyen instalaciones y bases del gobierno de EE. UU., instalaciones de mantenimiento de transporte, instalaciones automotrices, hospitales, instalaciones industriales y de fabricación, y muchas otras instalaciones. Nuestro personal diversificado incluye ingenieros ambientales y químicos, científicos y técnicos ambientales, y mano de obra calificada y capacitada. Nuestra empresa proporciona un ambiente de trabajo saludable y seguro a nuestros empleados y clientes."])}
      }
    }
  })
}
