<template>
  <section class="">
    <!-- Footer -->
    <footer class="text-center text-white" style="background-color: #255c00">
      <!-- Grid container -->
      <div class="container p-4 pb-0">
        <!-- Section: CTA -->
        <section class="">
          <div class="d-flex justify-content-between">
            <div class="d-none d-lg-flex">
              <ul class="mb-0 d-flex flex-row text-capitalize ps-2">
                <li>
                  <a
                    @click="this.$router.replace('/terms')"
                    class="text-white text-capitalize pointer"
                  >
                    {{t('terms')}}
                  </a>
                </li>
                <li class="mx-3">
                  <a
                    @click="this.$router.push('/privacy')"
                    class="text-white text-capitalize pointer"
                    > {{t('privacy')}}</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.cookiesandyou.com/"
                    target="_blank"
                    class="text-white text-capitalize"
                    > {{t('cookies')}}</a
                  >
                </li>
                <li class="mx-3">
                  <!-- <a
                      href="#cookies"
                      class="text-muted text-capitalize"
                    >
                      {{ $t("nav_book_btn") }}</a> -->
                </li>
              </ul>
            </div>
            <p
              class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <span class="me-3">{{t('cta')}}</span>
              <a
              v-if="getState == 'pr'"
                href="tel:787-675-9600"
                role="button"
                class="btn btn-md pointer btn-outline-white pointer btn-rounded mt-2 mt-lg-0"
              >
                <i class="fa fa-phone me-1"></i> {{t('call_btn')}}
              </a>
              <a
              v-if="getState == 'fl'"
                href="tel:904-677-9600"
                role="button"
                class="btn btn-md pointer btn-outline-white pointer btn-rounded mt-2 mt-lg-0"
              >
                <i class="fa fa-phone me-1"></i> {{t('call_btn')}}
              </a>
            </p>
          </div>
        </section>
        <!-- Section: CTA -->
      </div>
      <!-- Grid container -->

      <!-- Copyright -->
      <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
        © {{ new Date().getFullYear() }} Copyright:
        <a class="text-white" href="http://www.r4web.com/">R4web.com</a>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </section>
</template>

<i18n>
  {
    "en": {
      "terms": "Terms",
      "privacy": "Privacy",
      "cookies": "Cookies",
      "cta": 'Emergency Spills and Recovery Disaster',
      "call_btn": 'Call'
    },
    "es": {
      "terms": "términos",
      "privacy": "privacidad",
      "cookies": "galletas",
      "cta": 'Derrames de Emergencia y Desastre de Recuperación',
      "call_btn": 'Llamar'
    }
  }
  </i18n>

<script>
import { useI18n } from "vue-i18n";
import { mapGetters } from "vuex";

export default{
  setup(){
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    return{
      t
    }
  },

  computed:{
    ...mapGetters(["getState"]),
  }


}
</script>

