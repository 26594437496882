export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "equipment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment"])},
        "equipment_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R4 Enterprises Corporation has a variety of trucks and equipment to provide you with a turn key services in the Caribbean Area. R4 Enterprises operates a fleet of top-of-the-line power units, vans, tanks, roll off trucks and dump trailers, plus specialized equipment designed for sludge and waste handling. Equally as impressive are our drivers who we specially train to handle our various vehicles,manifests, labels, placards and emergency spill contingency plans. We are properly equipped in every aspect necessary to provide you with quality waste disposal service. R4 Enterprises provides annual training for our drivers, maintaining a strict obligation to keep our staff updated and educated on ever-changing at the Commonwealth and Federal Environmental and Transportation Regulations and Laws."])},
        "equipment_list": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alex"])}
        }
      },
      "es": {
        "equipment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipo"])},
        "equipment_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R4 Enterprises Corporation tiene una variedad de camiones y equipos para brindarle servicios llave en mano en el Área del Caribe. R4 Enterprises opera una flota de unidades de potencia, camionetas, tanques, camiones rodantes y remolques volquete de primera línea, además de equipos especializados diseñados para el manejo de lodos y desechos. Igualmente impresionantes son nuestros conductores, a quienes capacitamos especialmente para manejar nuestros diversos vehículos, manifiestos, etiquetas, carteles y planes de contingencia de derrames de emergencia. Estamos debidamente equipados en todos los aspectos necesarios para brindarle un servicio de eliminación de residuos de calidad. R4 Enterprises brinda capacitación anual para nuestros conductores, manteniendo la estricta obligación de mantener a nuestro personal actualizado y educado sobre las leyes y reglamentos ambientales y de transporte federales y del Commonwealth en constante cambio."])},
        "equipment_list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alejandro"])}
          }
        ]
      }
    }
  })
}
