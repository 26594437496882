<template>
  <section id="services">
    <div class="pb-5" style="background-color: #fafafa">
      <div class="container">
        <div class="row mx-0 h-100 my-5 g-2">
          <h3 class="text-center my-5">{{t('services_title')}}</h3>
          <p>
            {{t('services_desc')}}
          </p>
        </div>
        <div class="row">
          <div
            v-for="(service, index) in  getServices"
            :key="index"
            class="col-12 col-lg-4 mb-lg-0 pb-0 pb-lg-0"
          >
            <div class="card my-3 pt-2">
              <figure class="border-left border-primary ps-3 py-2 mb-4 px-5">
                <div class="text-truncate">
                  <h6 class="pb-3 text-capitalize">
                    <i
                      class="me-3"
                      :class="[service.icon]"
                      aria-hidden="true"
                    ></i
                    >{{ service.title }}
                  </h6>
                </div>
                <div class="d-flex justify-content-end align-items-end ms-4">
                  <MDBPopover dismissible v-model="popover_[index]">
                    <template #reference>
                      <MDBBtn
                        :disabled="service.list.length == 0"
                        class="btn-main"
                        size="sm"
                        @click="popover_[index] = !popover_[index]"
                        >More</MDBBtn
                      >
                    </template>
                    <template #header>
                      <span class=""> {{ service.title }}</span>
                    </template>
                    <template #body>
                      <ul v-if="service?.list">
                        <li v-for="(l, i) in service.list" :key="i">
                          {{ l }}
                        </li>
                      </ul>
                    </template>
                  </MDBPopover>
                </div>
                <!-- <figcaption class="blockquote-footer mb-0">
              <p class="font-weight-bold text-body mb-2">
                July, 2013 - Present (4 years)
              </p>
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </figcaption> -->
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n>
  {
    "en": {
      "services_title": "Services",
      "services_desc": "R4 Enterprises Corporation provides a diverse array of recycling, environmental and remediation services to industrial, commercial, and government clients. Project experiences include U.S. Government facilities and bases, transport maintenance facilities, automotive facilities, hospitals, industrial and manufacturing facilities, and numerous other facilities. Our diversified staff includes environmental and chemical engineers, environmental scientists and technicians, and qualify and trained labors. Our company provides a healthy and safely working environment to our employees and clients."
      
    },
    "es": {
      "services_title": "Servicios",
      "services_desc": "R4 Enterprises Corporation brinda una amplia gama de servicios de reciclaje, ambientales y de remediación a clientes industriales, comerciales y gubernamentales. Las experiencias de proyectos incluyen instalaciones y bases del gobierno de EE. UU., instalaciones de mantenimiento de transporte, instalaciones automotrices, hospitales, instalaciones industriales y de fabricación, y muchas otras instalaciones. Nuestro personal diversificado incluye ingenieros ambientales y químicos, científicos y técnicos ambientales, y mano de obra calificada y capacitada. Nuestra empresa proporciona un ambiente de trabajo saludable y seguro a nuestros empleados y clientes."
     
    }
  }
  </i18n>

<script>
import { useI18n } from "vue-i18n";
import { MDBPopover, MDBBtn } from "mdb-vue-ui-kit";
import { ref } from "vue";
export default {
  components: {
    MDBPopover,
    MDBBtn,
  },

  computed:{
    getServices(){
      if(this.$i18n.locale == 'en'){
        return this.servicesEn
      }
      else{
        return this.servicesEs
      }
      // this.$i18n.locale == 'en' ? return this.equipmentsen : return this.equipmentsEs
      
    }
  },
  setup() {
    const popover_ = ref([]);
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    const servicesEn = ref([
      {
        title: "Recycling and disposable services",
        list: [
          "Scrap Tires",
          "Scrap Metals",
          "Scrap Filters",
          "Used Oil",
          "Absorbents Materials and Pampers",
          "Antifreezes / Coolants",
          "Contaminated Soils",
          "Wastewater (process water, oily waters, etc.)",
          "Asbestos Containing Materials (ACM)",
          "Construction and Demolition (C&D)",
          "Yard Waste (Wood, Pallets, etc.)",
        ],
        icon: "fas fa-recycle",
      },
      {
        title: "Environmental & remidiation services",
        list: [
          "Environmental Impact Studies & Assessment Services",
          "Environmental Impact Studies",
          "Phase I & II Environmental Site Assessments",
        ],
        icon: "fas fa-earth-america",
      },
      {
        title: "Contaminated site assessment & remidiation ",
        list: [
          "Excavation, transportation and disposal services",
          "Field Monitoring Services",
        ],
        icon: "fas fa-shield-virus",
      },

      {
        title: "Waste Management services ",
        list: [
          "Hazardous Waste Management Services",
          "Arranging Comprehensive Waste Transportation Services",
          "Comprehensive brokering of waste treatment services",
          "Laboratory characterization/identification of containerized waste",
          "Industrial & commercial wastewater treatment services",
        ],
        icon: "fas fa-biohazard",
      },
      {
        title: "Industrial Services",
        list: [
          "Industrial Cleaning and Maintenance Services",
          "High Pressure Washing Utilizing Cold Water, Hot Water or Steam",
          "Surface Decontamination by OSHA 40-Hour Hazwoper Trained Personnel",
          "Tank and Vault Cleaning by Personnel Certified for Work in Confined Spaces",
          "Machinery and Conveyor Decontamination",
          "Machinery Maintenance and Repair, including welding and other repairs in potentially hazardous environments and confined spaces",
        ],
        icon: "fas fa-industry",
      },
      {
        title: "Underground storage tank services ",
        list: [
          "UST Site Assessment, Closure and Remedial Action",
          "Laboratory Analysis of Groundwater, Soil, and Soil Vapor",
          "Federal, State and Local Regulatory Compliance and Permitting",
          "Construction Management",
          "New Tank Installation (Underground and/or Aboveground)",
        ],
        icon: "fas fa-box",
      },
      {
        title: "Equipment dismantling services ",
        list: [
          "Our company personnel will provide a turn key job for your company, dismantling the equipment in the Caribbean Area, safely packing it, and finally working on the logistic all the way to any location around the world.",
        ],
        icon: "fas fa-truck",
      },
      {
        title: "Ship dismantling & recycling ",
        list: [],
        icon: "fas fa-ship",
      },
      {
        title: "Pharmaceutical dismantling & packaging",
        list: [],
        icon: "fas fa-prescription-bottle",
      },
      {
        title: "Concrete plant demolition & recycling",
        list: [],
        icon: "fas fa-building",
      },
      {
        title: "Commercial Mall Demolition",
        list: [],
        icon: "fas fa-store",
      },
    ]);

    const servicesEs = ref([
      {
        title: "Servicios de reciclaje y desechables",
        list: [
          "Llantas de desecho",
          "Chatarras",
          "Filtros de chatarra",
          "Aceite usado",
          "Materiales Absorbentes y Pampers",
          "Anticongelantes / Refrigerantes",
          "Suelos Contaminados",
          "Aguas residuales (aguas de proceso, aguas aceitosas, etc.)",
          "Materiales que contienen asbesto (ACM)",
          "Construcción y Demolición (C&D)",
          "Residuos de jardín (madera, tarimas, etc.)",
        ],
        icon: "fas fa-recycle",
      },
      {
        title: "Servicios ambientales y de remediación",
        list: [
          "Estudios de Impacto Ambiental y Servicios de Evaluación",
          "Estudios de Impacto Ambiental",
          "Evaluaciones Ambientales del Sitio Fase I y II",
        ],
        icon: "fas fa-earth-america",
      },
      {
        title: "Evaluación y remediación de sitios contaminados ",
        list: [
          "Servicios de excavación, transporte y disposición",
          "Servicios de monitoreo de campo",
        ],
        icon: "fas fa-shield-virus",
      },

      {
        title: "Servicios de gestión de residuos ",
        list: [
          "Servicios de gestión de residuos peligrosos",
          "Organización de servicios integrales de transporte de residuos",
          "Intermediación integral de servicios de tratamiento de residuos",
          "Caracterización/identificación en laboratorio de residuos en contenedores",
          "Servicios de tratamiento de aguas residuales industriales y comerciales",
        ],
        icon: "fas fa-biohazard",
      },
      {
        title: "Servicios Industriales",
        list: [
          "Servicios de Limpieza y Mantenimiento Industrial",
          "Lavado a alta presión con agua fría, agua caliente o vapor",
          "Descontaminación de superficies por parte de personal capacitado OSHA 40-Hour Hazwoper",
          "Limpieza de Tanques y Bóvedas por Personal Certificado para Trabajo en Espacios Confinados",
          "Descontaminación de Maquinaria y Transportadores",
          "Mantenimiento y reparación de maquinaria, incluida la soldadura y otras reparaciones en entornos potencialmente peligrosos y espacios confinados",
        ],
        icon: "fas fa-industry",
      },
      {
        title: "Servicios de tanques de almacenamiento subterráneo ",
        list: [
          "Evaluación del sitio de UST, cierre y acción correctiva",
          "Análisis de laboratorio de agua subterránea, suelo y vapor de suelo",
          "Cumplimiento normativo y permisos federales, estatales y locales",
          "Gestión de la construcción",
          "Instalación de nuevos tanques (subterráneos y/o aéreos)",
        ],
        icon: "fas fa-box",
      },
      {
        title: "Servicios de desmontaje de equipos ",
        list: [
          "El personal de nuestra empresa proporcionará un trabajo llave en mano para su empresa, desmantelando el equipo en el Área del Caribe, empaquetándolo de manera segura y, finalmente, trabajando en la logística hasta cualquier lugar del mundo.",
        ],
        icon: "fas fa-truck",
      },
      {
        title: "Desguace y reciclaje de barcos ",
        list: [],
        icon: "fas fa-ship",
      },
      {
        title: "Desmantelamiento y embalaje farmacéutico",
        list: [],
        icon: "fas fa-prescription-bottle",
      },
      {
        title: "Demolición y reciclaje de plantas de hormigón",
        list: [],
        icon: "fas fa-building",
      },
      {
        title: "Demolición de centro comercial",
        list: [],
        icon: "fas fa-store",
      },
    ]);

    return { servicesEn, servicesEs , popover_, t };
  },
};
</script>

<!-- TODO add images to popup -->


<!-- TODO Services List -->