<template>
  <section id="contact">
  
    <div class="pb-5" style="background-color: #fafafa">
      <div class="container">
        <div class="row mx-0 h-100 my-5 g-2">
          <h3 class="text-center my-5">Contact</h3>
          <div class="col-12 col-lg-6 rounded-5 ratio ratio-16x9">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3444.08047558539!2d-81.7833219!3d30.320228699999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e5bed4171da1a7%3A0xc53901a023ca25db!2s265%20Cahoon%20Rd%20S%2C%20Jacksonville%2C%20FL%2032220%2C%20USA!5e0!3m2!1sen!2spr!4v1668514762652!5m2!1sen!2spr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div class="col-12 col-lg-4 offset-0 ">
            <div class="row mx-0">
              <div class="col-1 col-md-2 float-start">
                <i class="fas fa-location-arrow fa-2x text-main"></i>
              </div>
              <div class="col-11 col-md-9 col-lg-10 float-end">
                <h6 class="text-main mt-2">Address</h6>
                <p v-if="getState == 'pr'">
                  Cintrona Ward , El Pastillo Sector, State Road #2 Km 113.6
                  Juana Diaz, PR 00795
                </p>
                <p v-if="getState == 'fl'">
                  265 Cahoon RD S Jacksonville FL 32220
                </p>
              </div>
              <div class="col-1 col-md-2 float-start">
                <i class="fas fa-envelope fa-2x text-main"></i>
              </div>
              <div class="col-11 col-md-9 col-lg-10 float-end">
                <h6 class="text-main mt-2 ms-2">Email</h6>
                <a class="text-muted ms-2" href="mailto:info@r4web.com">
                  R4Web
                </a>
              </div>
              <div class="col-1 col-md-2 float-start mt-4">
                <i class="fas fa-phone fa-2x text-main"></i>
              </div>
              <div  v-if="getState == 'pr'" class="col-10 col-md-9 col-lg-10 float-end">
                <h6 class="text-main mt-4 ms-2">Telephones</h6>
                <a class="text-muted ms-2" href="tel:787-675-9600">Primary: 787-675-9600</a>
                <a class="text-muted ms-2 d-block" href="tel:787-260-6003"
                  >Secondary: 787-260-6003</a
                >
              </div>
              <div  v-if="getState == 'fl'" class="col-10 col-md-9 col-lg-10 float-end">
                <h6 class="text-main mt-4 ms-2">Telephones</h6>
                <a class="text-muted ms-2" href="tel:787-675-9600">Primary: 904-677-9600</a>
                <!-- <a class="text-muted ms-2 d-block" href="tel:787-260-6003"
                  >Secondary: 787-260-6003</a
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 
</template>

<script>
import {ref} from 'vue';
import { mapGetters } from "vuex";

export default{

  setup(){
    const state = ref('pr');
 
    return{
      state,
  
    }
  },
  computed:{
    ...mapGetters(["getState"]),
  },
  mounted(){
  
    localStorage.getItem('state') ? this.state = localStorage.getItem('state') : localStorage.setItem('state', this.state)
    this.$store.commit('setState', this.state)
  }
}
</script>
