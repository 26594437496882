export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recycle, Reuse, Recover, Reduce"])},
        "call_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Now"])}
      },
      "es": {
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciclar, Reutilizar, Recuperar, Reducir"])},
        "call_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llama Ahora"])}
      }
    }
  })
}
