<template>
  <div>
    <MDBModal
      position="bottom-right"
      side
      direction="right"
      id="exampleFrameModal1"
      tabindex="-1"
      labelledby="exampleFrameModal1"
      v-model="exampleFrameModal1"
    >
      <MDBModalBody class="py-1">
        <div
          class="d-flex justify-content-lg-between justify-content-center align-items-center my-3 flex-wrap"
        >
          <!-- We value your privacy -->
          <!-- We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. -->
          <h4></h4>
          <p class="pt-3 mx-4">
            We use cookies to enhance your browsing experience, serve
            personalized ads or content, and analyze our traffic. By clicking
            "Accept", you consent to our use of cookies
          </p>
          <div class="d-flex justify-content-end w-100">
            <MDBBtn
              size="sm"
              class="ms-2 main-btn-outline"
              href="https://www.cookiesandyou.com/"
              target="_blank"
              tag="a"
            >
              More Info
            </MDBBtn>
            <MDBBtn @click="setCookies()" size="sm" class="ms-2 main-bg">
              Accept
            </MDBBtn>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  </div>
</template>

<script>
import { MDBModal, MDBModalBody, MDBBtn } from "mdb-vue-ui-kit";
import { ref } from "vue";
export default {
  components: {
    MDBModal,
    MDBModalBody,
    MDBBtn,
  },
  setup() {
    const exampleFrameModal1 = ref(false);
    return {
      exampleFrameModal1,
    };
  },

  methods: {
    setCookies() {
      localStorage.setItem("cookies", "true");
      this.exampleFrameModal1 = false;
    },
  },

  mounted() {
    localStorage.getItem("cookies") == "true"
      ? null
      : (this.exampleFrameModal1 = true);
  },
};
</script>
