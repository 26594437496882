<template>
  <section id="equipment"></section>
  <div class="container">
    <div class="row mx-0 h-100 my-5">
      <h3 class="text-center my-5">  {{t('equipment_title')}}</h3>
     
      <div class="col-12 col-lg-6">
        <div class="row mx-0">
          <div class="col-6">
            <img src="https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/jpeg-optimizer-1.9461fd43.webp" class="img-fluid" alt="" />
          </div>
          <div class="col-6 offset-6">
            <img src="https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/jpeg-optimizer-2.97b62350.webp" class="img-fluid" alt="" />
          </div>
          <div class="col-6">
            <img src="https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/jpeg-optimizer-3.8d627a97 (1).webp" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-4 mt-lg-0">
        <p>
         {{t('equipment_desc')}}
        </p>
        <!-- {{this.$i18n.locale}} -->
        <ul class="list-unstyled mb-0">
          <li v-for="(e, i) in getEq" :key="i">
            <i class="fas fa-check me-2"></i>{{ e }}
          </li>
        </ul>
        <!-- <ul class="list-unstyled mb-0">
          <li v-for="(e, i) in equipments" :key="i">
            <i class="fas fa-check me-2"></i>{{ e }}
          </li>
        </ul> -->
        <!-- {{t('equipment_list', {returnObjects: true}).length}} -->
        <!-- <p v-for="(item , index) in $t(`equipment_list`, {
        returnObjects: true,
      })" :key="index">
          Hey{{item.name}}
        </p>
        Alex2
        {{t('equipment_list')}} -->
      </div>
    </div>
  </div>
</template>

<i18n>
  {
    "en": {
      "equipment_title": "Equipment",
      "equipment_desc": "R4 Enterprises Corporation has a variety of trucks and equipment to provide you with a turn key services in the Caribbean Area. R4 Enterprises operates a fleet of top-of-the-line power units, vans, tanks, roll off trucks and dump trailers, plus specialized equipment designed for sludge and waste handling. Equally as impressive are our drivers who we specially train to handle our various vehicles,manifests, labels, placards and emergency spill contingency plans. We are properly equipped in every aspect necessary to provide you with quality waste disposal service. R4 Enterprises provides annual training for our drivers, maintaining a strict obligation to keep our staff updated and educated on ever-changing at the Commonwealth and Federal Environmental and Transportation Regulations and Laws.",
      "equipment_list":  {"name": "Alex"}
      
    },
    "es": {
      "equipment_title": "Equipo",
      "equipment_desc": "R4 Enterprises Corporation tiene una variedad de camiones y equipos para brindarle servicios llave en mano en el Área del Caribe. R4 Enterprises opera una flota de unidades de potencia, camionetas, tanques, camiones rodantes y remolques volquete de primera línea, además de equipos especializados diseñados para el manejo de lodos y desechos. Igualmente impresionantes son nuestros conductores, a quienes capacitamos especialmente para manejar nuestros diversos vehículos, manifiestos, etiquetas, carteles y planes de contingencia de derrames de emergencia. Estamos debidamente equipados en todos los aspectos necesarios para brindarle un servicio de eliminación de residuos de calidad. R4 Enterprises brinda capacitación anual para nuestros conductores, manteniendo la estricta obligación de mantener a nuestro personal actualizado y educado sobre las leyes y reglamentos ambientales y de transporte federales y del Commonwealth en constante cambio.",
      "equipment_list":   [{"name": "Alejandro"}]
    }
  }
  </i18n>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
export default {
  computed: {
    getEq(){
      if(this.$i18n.locale == 'en'){
        return this.equipmentsen
      }
      else{
        return this.equipmentsEs
      }
      // this.$i18n.locale == 'en' ? return this.equipmentsen : return this.equipmentsEs
      
    }
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    const equipmentsen = ref([
      "Vacuum Truck",
      "Truck Trailers",
      "Van Trailers (45-53 feet)",
      "Cube Van Trucks (22-24 feet)",
      "Dump Trailers",
      "Roll Off Truck, 20 & 40 cubic yards containers",
      "Low Boy",
      "Excavator, Grapper Attachment",
      "D-4",
      "Backhoe",
      "Diamond Z Grinder (Mobil Unit) Model: 1463BT",
      "Mobile Steel Compactor",
      "Bobcat",
    ]);
    const equipmentsEs= ref([
      "Camión de vacío",
      "Remolques de camiones",
      "Remolques de furgonetas (45-53 pies)",
      "Camionetas Cubo (22-24 pies)",
      "Remolques volcados",
      "Roll Off Truck, contenedores de 20 y 40 yardas cúbicas",
      "Camión de plataforma baja",
      "Excavadora, Accesorio Grapper",
      "D-4",
      "Retroexcavadora",
      "Amoladora Diamond Z (unidad móvil) Modelo: 1463BT",
      "Compactador de acero móvil",
      "Bobcat",
    ]);
    return {
      equipmentsen,
      equipmentsEs,
      t
    };
  },
};
</script>
