<template>
  <section id="info">
    <div class="container">
      <div class="row mx-0 h-100 my-5">
        <h3 class="text-center my-5">{{t('about_title')}}</h3>
        <div
          class="col-12 col-lg-6 d-flex align-items-center justify-content-center"
        >
          <img src="https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/about.689b7e25.webp" class="img-fluid rounded-5" alt="" />
        </div>
        <div
          class="col-12 col-lg-6 d-flex align-items-center justify-content-center px-2 px-lg-5 pt-4 pt-lg-0"
        >
          <div>
            <p>
              {{t('about_1')}}
            </p>
            <p>
             {{t('about_2')}}
            </p>
          </div>
        </div>
      </div>

      <div class="row mx-0 h-100 my-5">
        <div
          class="col-12 col-lg-6 d-flex align-items-center justify-content-center px-2 px-lg-5 order-1 order-lg-0 pt-4 pt-lg-0"
        >
          <div>
            <p>
              {{t('about_3')}}
            </p>
            <p>
              {{t('about_4')}}
            </p>
          </div>
        </div>
        <div
          class="col-12 col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1"
        >
          <img src="https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/about_2.ec4f6030_640x480.webp" class="img-fluid rounded-5" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>



<i18n>
  {
    "en": {
      "about_title": "About",
      "about_1": "R4 ENTERPRISES CORPORATION (R4) is a service company established in 1997 (incorporated in the Commonwealth of Puerto Rico) to provide recycling, environmental and remediation services to the government and industry. Throughout the years, our company has developed a complete turnkey job service for our clients; either, servicing their facilities, or executing field projects.",
      "about_2": "At R4, we recognize that our long-term success is unconditionally dependent on the quality of the services we provide and on the satisfaction of our clients. We believe that open and effective communication is the key to consistent quality performance. Only by listening to our clients can we understand and accurately respond to their specific concerns.",
      "about_3": "We address each client and each environmental or regulatory issue in a personal, individualized manner (case by case manner). We do not rely on standard recipe answers or methods but rather seek to identify the best solution for each unique set of circumstances. R4 has expended significant time and resources to develop and expand its service capabilities by building relationships with qualified recycling, treatment and disposal facilities, others specialized facilities, and outside consultants. Through these efforts, R4 has developed a network of allied specialized facilities and individuals with a shared commitment to quality service.",
      "about_4": "R4 Enterprises Corporation provides a diverse array of recycling, environmental and remediation services to industrial, commercial, and government clients.",
      
    },
    "es": {
      "about_title": "Sobre Nosotros",
      "about_1": "R4 ENTERPRISES CORPORATION (R4) es una compañía de servicios establecida en 1997 (incorporada en el Estado Libre Asociado de Puerto Rico) para brindar servicios de reciclaje, ambientales y de remediación al gobierno y la industria. A lo largo de los años, nuestra empresa ha desarrollado un completo servicio de trabajos llave en mano para nuestros clientes; ya sea, dando servicio a sus instalaciones, o ejecutando proyectos de campo.",
      "about_2": "En R4, reconocemos que nuestro éxito a largo plazo depende incondicionalmente de la calidad de los servicios que brindamos y de la satisfacción de nuestros clientes. Creemos que la comunicación abierta y efectiva es la clave para un desempeño de calidad consistente. Solo escuchando a nuestros clientes podemos entender y responder con precisión a sus inquietudes específicas.",
      "about_3": "Abordamos cada cliente y cada tema ambiental o regulatorio de manera personal e individualizada (caso por caso). No confiamos en respuestas o métodos de recetas estándar, sino que buscamos identificar la mejor solución para cada conjunto único de circunstancias. R4 ha invertido mucho tiempo y recursos para desarrollar y ampliar sus capacidades de servicio mediante la creación de relaciones con instalaciones calificadas de reciclaje, tratamiento y eliminación, otras instalaciones especializadas y consultores externos. A través de estos esfuerzos, R4 ha desarrollado una red de instalaciones e individuos especializados aliados con un compromiso compartido con el servicio de calidad.",
      "about_4": "R4 Enterprises Corporation brinda una amplia gama de servicios de reciclaje, ambientales y de remediación a clientes industriales, comerciales y gubernamentales.", 
    }
  }
  </i18n>

<script>
import { useI18n } from "vue-i18n";

export default{
  setup(){
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    return{
      t
    }
  },


}
</script>
