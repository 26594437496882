<template>
  <div >
  <Header ></Header>
  <!-- <div id="google_translate_element"></div> -->
  <!-- <Translator v-if="false" :countries="c" /> -->
  <Slider ></Slider>
  <!-- <div v-if="!saving">
    Alex2
    <div id="google_translate_element"></div>
  </div> -->
  <About v-if="!saving "></About>
  <Cta v-if="!saving "></Cta>
  <Services v-if="!saving"></Services>
  <Equipment v-if="!saving  "></Equipment>
  <Contact v-if="true"></Contact>
  <Footer v-if="!saving"></Footer>
  <Cookies v-if="!saving"></Cookies>
  <Loader v-model:saving="saving"></Loader>
  <!-- <div class="vh-100">sadas</div> -->
</div>
</template>

<script>
// @ is an alias to /src
// import { Translator } from "vue-google-translate";
import Header from "../components/main-header.vue";
import Slider from "../components/main-slider.vue";
import About from "../components/main-about.vue";
import Services from "../components/main-services";
import Equipment from "../components/main-equipment.vue";
import Cta from "../components/main-cta.vue";
import Contact from "../components/main-contact";
import Footer from "../components/main-footer";
import Cookies from "../components/main-cookies.vue";
import Loader from "../components/main-loader";

import { ref } from "vue";

export default {
  name: "HomeView",
  components: {
    // Translator,
    Header,
    Slider,
    About,
    Services,
    Equipment,
    Cta,
    Contact,
    Footer,
    Cookies,
    Loader,
  },
  setup() {
    const saving = ref(true);
    const c = ref([
      {
        code: "en|iw",
        title: "Hebrew",
        flagIconUrl: "https://flagicons.lipis.dev/flags/4x3/il.svg",
        altText: "Hebrew language translation",
      },
      {
        code: "ga|pl",
        title: "Polish",
        flagIconUrl: "https://flagicons.lipis.dev/flags/4x3/pl.svg",
        altText: "Poland flag Icon",
      },
    ]);
    return { c, saving };
  },
  methods: {
    handleScroll(event) {
      console.log("SCroling:", event);
    },
  },
  mounted() {
    setTimeout(() => {
      this.saving = false;
    }, 2000);
    // window.addEventListener("scroll", function () {
    //   if(this.scrollY == 0){
    //   }
    //   console.log(this.scrollY);
    // });
    // window.addEventListener("scroll", (event) => {
    //   event;
    //   let scroll = event.scrollY;
    //   console.log(scroll);
    // });
    // window.addEventListener("scroll", this.handleScroll(event), false);
    // window.addEventListener("scroll", function (event) {
    //   var element = event.target;
    //   console.log(element);
    //   if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //     console.log("scrolled");
    //   }
    // });
  },
};
</script>
