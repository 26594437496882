export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello world!"])},
        "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYCLING / ENVIRONMENTAL / WASTE / INDUSTRIAL"])},
        "mini_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking Steps Forward Into A Cleaner Enviroment."])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location changed to "])}
      },
      "es": {
        "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hola"])},
        "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECICLAJE / AMBIENTAL / RESIDUOS / INDUSTRIALES"])},
        "mini_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dando pasos hacia adelante hacia un medio ambiente más limpio."])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación cambiada a "])}
      }
    }
  })
}
