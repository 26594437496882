<template>
  <div
    v-if="saving"
    class="mask"
    style="backdrop-filter: blur(5px); background-color: #fff; z-index: 10000"
  >
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div>
        <h1 class="display-6">R4 Enterprises</h1>
        <div class="text-center">
          <img src="../assets/loader.gif" alt="" height="70" />
        </div>
        <!-- <div class="text-center mt-3">
          <div class="spinner-border text-white" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["saving"],
};
</script>
