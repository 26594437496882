import 'babel-polyfill'
import 'core-js/stable'
// import 'core-js/es6/array';
// import 'core-js/es7/array';
import 'regenerator-runtime/runtime';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { createApp } from 'vue'
import 'mdb-vue-ui-kit/css/mdb.min.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import "firebase/compat/analytics";


import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App)
app.use(VueReCaptcha, { siteKey: '6LcSjykgAAAAAP_fv853UUn2naYnKYyUSP1alrbh' })

const firebaseConfig = {
    apiKey: "AIzaSyCSrPSSgPIaVGSr_xaXrpugBPAmjUjzy2w",
    authDomain: "julito-homes.firebaseapp.com",
    projectId: "julito-homes",
    storageBucket: "julito-homes.appspot.com",
    messagingSenderId: "477447587085",
    appId: "1:477447587085:web:351e7b4cf44e66cb235c44",
    measurementId: "G-SPV58GFRBX"
  };

  firebase.initializeApp(firebaseConfig)
  firebase.analytics().logEvent('notification_received');

app.use(i18n).use(store).use(router).mount('#app')