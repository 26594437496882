export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
        "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Spills and Recovery Disaster"])},
        "call_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])}
      },
      "es": {
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["términos"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacidad"])},
        "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["galletas"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derrames de Emergencia y Desastre de Recuperación"])},
        "call_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamar"])}
      }
    }
  })
}
