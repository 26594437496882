<template>
  <nav
    class="navbar fixed-top navbar-dark d-block d-lg-none"
    :class="{
      'shadow-5-strong': $route.name == 'terms' || $route.name == 'privacy',
    }"
    :style="{ 'background-color': navBG }"
  >
    <div class="d-flex justify-content-between px-3">
      <div class="col">
        <a href="/">
          <img :src="logo" height="50" alt="" />
        </a>
      </div>
      <!-- <div>
        <i class="fa fa-chevron-left"></i>
      </div> -->
      <div v-if="$route.name == 'home'" class="col d-flex justify-content-end align-items-center">
        <button
          id="myToggle"
          class="menu d-inline d-lg-none"
          @click="openMenu()"
        >
          <svg width="50" height="50" viewBox="0 0 100 100" class="text-muted">
            <path
              class="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path class="line line2" d="M 20,50 H 80" />
            <path
              class="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>
      </div>
    </div>
  </nav>
  <nav
    class="navbar navbar-expand-lg navbar-dark d-none d-lg-block fixed-top navbar-scroll"
    :class="{
      'shadow-5-strong': $route.name == 'terms' || $route.name == 'privacy',
    }"
    :style="{
      'background-color': navBG,
    }"
    style="z-index: 2000"
  >
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="col">
          <a href="/">
            <img :src="logo" height="65" alt="" />
          </a>
        </div>
        <div class="col d-flex justify-content-center">
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li v-for="(m, i) in getMenu" :key="i" class="nav-item active">
              <a
                class="nav-link text-capitalize"
                aria-current="page"
                :href="m.link"
                >{{ m.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="col d-flex justify-content-end">
          <div class="d-flex justify-content-center align-items-center">
            <a
            v-if="getState == 'pr'"
              href="tel:787-675-9600"
              role="button"
              class="btn btn-md pointer btn-outline-main pointer btn-rounded"
            >
              <i class="fa fa-phone me-1"></i> {{t('call_btn')}}
            </a>

            <a
            v-if="getState == 'fl'"
              href="tel:904-677-9600"
              role="button"
              class="btn btn-md pointer btn-outline-main pointer btn-rounded"
            >
              <i class="fa fa-phone me-1"></i> {{t('call_btn')}}
            </a>
          </div>
      
          <MDBDropdown class="nav-item" v-model="flagDropdown">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="flagDropdown = !flagDropdown"
            >
              <MDBIcon flag="us" class="m-0"></MDBIcon>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem @click="changeLanguage('us')" href="#!"
                ><MDBIcon flag="us" />English
                <MDBIcon
                  v-if="$i18n.locale == 'en'"
                  icon="check"
                  class="main-color ms-2"
                ></MDBIcon>
                <MDBIcon class="text-success ms-2"></MDBIcon>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
              <MDBDropdownItem @click="changeLanguage('pr')" href="#!"
                ><MDBIcon flag="pr" />Español
                <MDBIcon
                  v-if="$i18n.locale == 'es'"
                  icon="check"
                  class="main-color ms-2"
                ></MDBIcon>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
    </div>
  </nav>

  <MDBModal
    id="exampleModal"
    tabindex="-1"
    labelledby="exampleModalLabel"
    v-model="exampleModal"
    @hide="hideMenu"
    fullscreen
    direction="right"
  >
    <MDBModalBody class="h-100">
      <div
        @click="hideMenu()"
        class="d-flex justify-content-end"
        style="z-index: 100"
      >
        <svg
          class="pointer"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          color="#757575"
          style="z-index: 100000"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <img
          src="../assets/logo_green_dark.png"
          height="120"
          alt=""
          class="mb-4"
        />
      </div>

      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column justify-content-center text-center">
          <a
            v-for="(mm, mi) in getMenu"
            :key="mi"
            class="pointer display-6 text-uppercase my-2 text-muted"
            :href="mm.link"
            @click="this.exampleModal = false"
          >
            <h4>{{ mm.title }}</h4>
          </a>
        </div>
      </div>

      <div class="w-100 fixed-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="mb-0 d-flex flex-row text-capitalize ps-2">
            <li>
              <a
                @click="this.exampleModal = false, this.$router.push('/terms')"
                class="text-muted text-capitalize pointer"
              >
                Terms
              </a>
            </li>
            <li class="mx-3">
              <a
                @click="this.exampleModal = false, this.$router.push('/privacy')"
                class="text-muted text-capitalize pointer"
                >Privacy</a
              >
            </li>
            <li>
              <a
                href="https://www.cookiesandyou.com/"
                target="_blank"
                class="text-muted text-capitalize"
                >Cookies</a
              >
            </li>
            <li class="mx-3">
              <!-- <a
                      href="#cookies"
                      class="text-muted text-capitalize"
                    >
                      {{ $t("nav_book_btn") }}</a> -->
            </li>
          </ul>
          <MDBDropdown class="nav-item" v-model="flagDropdown2">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="flagDropdown2 = !flagDropdown2"
            >
              <MDBIcon flag="us" class="m-0"></MDBIcon>
              <!-- <MDBIcon
                  v-if="$i18n.locale != 'en'"
                  icon="check"
                  class="main-color ms-2"
                ></MDBIcon> -->
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem tag="div">
                This{{$i18n.locale}}
              </MDBDropdownItem>
              <MDBDropdownItem
                tag="div"
                @click="changeLanguage('pr')"
                class="pointer"
              >
                <MDBIcon flag="pr" />Español
                <MDBIcon
                  v-if="$i18n.locale == 'es'"
                  icon="check"
                  class="main-color ms-2"
                ></MDBIcon>
              </MDBDropdownItem>
              <MDBDropdownItem
                tag="div"
                @click="changeLanguage('us')"
                class="pointer"
              >
                <MDBIcon flag="us" />English
                <MDBIcon
                  v-if="$i18n.locale == 'en'"
                  icon="check"
                  class="main-color ms-2"
                ></MDBIcon>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
    </MDBModalBody>
  </MDBModal>
  <MDBAlert
    class="mt-3 text-center"
    color="success"
    v-model="languageChangeAlert"
    ref="hiddenAlertRef"
    autohide
    appendToBody
    :delay="3000"
    position="bottom-center"
    width="320px"
  >
     {{ language == "us" ? "Language has changed to English" : "El idioma ha cambiado a Español" }}.
  </MDBAlert>
</template>

<script>
import logo_white from "../assets/logo_white.png";
import logo_green from "../assets/logo_green_dark.png";
import { mapGetters } from "vuex";
import { useI18n } from "vue-i18n";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBAlert,
} from "mdb-vue-ui-kit";
import { ref } from "vue";
export default {
  components: {
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBAlert,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    const languageChangeAlert = ref(false);
    const menuEn = ref([
      {
        title: "home",
        link: "#intro",
      },
      {
        title: "info",
        link: "#info",
      },
      {
        title: "services",
        link: "#services",
      },
      {
        title: "equipment",
        link: "#equipment",
      },
      {
        title: "contact",
        link: "#contact",
      },
    ]);
    const menuEs = ref([
      {
        title: "Iniciar",
        link: "#intro",
      },
      {
        title: "info",
        link: "#info",
      },
      {
        title: "servicios",
        link: "#services",
      },
      {
        title: "equipo",
        link: "#equipment",
      },
      {
        title: "contacto",
        link: "#contact",
      },
    ]);
    const logo = ref(logo_white);
    const navBG = ref("transparent");
    const navLinkColor = ref("#fff");
    const flagDropdown = ref(false);
    const flagDropdown2 = ref(false);
    const mainColor = ref("#fff");
    const exampleModal = ref(false);
    const language = ref("us");
    const language2 = ref("us");
    return {
      menuEn,
      menuEs,
      flagDropdown,
      flagDropdown2,
      navBG,
      logo,
      navLinkColor,
      mainColor,
      exampleModal,
      language,
      language2,
      t,
      languageChangeAlert,
    };
  },

  computed:{
    getMenu(){
      if(this.$i18n.locale == 'en'){
        return this.menuEn
      }
      else{
        return this.menuEs
      }
      // this.$i18n.locale == 'en' ? return this.equipmentsen : return this.equipmentsEs
      
    },
    ...mapGetters(["getState"]),
    
  },

  methods: {
    handleScroll(e) {
      console.log(e);
    },

    openMenu() {
      console.log("MEnu");
      this.exampleModal = !this.exampleModal;
      var body = document.body;
      var t = document.getElementById("myToggle");
      this.exampleModal
        ? t.classList.add("opened")
        : t.classList.remove("opened");
      this.exampleModal
        ? body.classList.add("myOverflow")
        : body.classList.remove("myOverflow");
    },

    hideMenu() {
      this.exampleModal = false;
      var body = document.body;
      var t = document.getElementById("myToggle");
      this.exampleModal
        ? t.classList.add("opened")
        : t.classList.remove("opened");
      this.exampleModal
        ? body.classList.add("myOverflow")
        : body.classList.remove("myOverflow");
    },

    changeLanguage(lng) {
      console.log("Change Language: ", lng);
      this.language = lng == "us" ? "us" : "pr";
      this.language2 = lng == "us" ? "us" : "pr";
      this.$i18n.locale = lng == "us" ? "en" : "es";
      this.flags = false;
      this.flagsModal = false;
      this.languageChangeAlert = true;
      localStorage.setItem("language",  this.$i18n.locale );
      this.flagDropdown = false;
      // this.$store.commit("setLng", this.$i18n.locale);
      // console.log("Changing: ", this.$i18n.locale);
    },
  },

  mounted() {
    if (this.$route.name != "home") {
      this.logo = logo_green;
      this.navBG = "#fff";
      this.navLinkColor = "#757575";
      this.mainColor = "#255C00";
    }
    window.addEventListener("scroll", () => {
      if (this.$route.name == "home") {
        scrollY < 80 ? (this.logo = logo_white) : (this.logo = logo_green);
        scrollY < 80 ? (this.navBG = "transparent") : (this.navBG = "#fff");
        scrollY < 80
          ? (this.navLinkColor = "#fff")
          : (this.navLinkColor = "#757575");
        scrollY < 80 ? (this.mainColor = "#fff") : (this.mainColor = "#255C00");
      }
    });

    this.$i18n.locale = localStorage.getItem("language")

    // if (this.$route.name == "privacy" || this.$route.name == "terms") {
    //   this.navBG = "#757575";
    // }
    // window.addEventListener("scroll", this.handleScroll(this.scrollY));
    // window.addEventListener("scroll", (event) => {
    //   event;
    //   console.log("SCroll: ", this.scrollY);
    //   if (this.scrollY < 80) {
    //     console.log("White");
    //     this.logo = logo_white;
    //   } else {
    //     console.log("green");
    //     this.logo = logo_green;
    //   }
    // });
  },
};
</script>

<style>
.navbar {
  box-shadow: none;
  padding-top: 0.5625rem;
}

/* .navbar {
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
  padding-top: 0.5625rem;
} */
.btn-outline-main {
  border: 1px solid v-bind(mainColor);
  color: v-bind(mainColor) !important;
}
.nav-link {
  color: v-bind(navLinkColor) !important;
}

i.fa-bars {
  color: v-bind(navLinkColor) !important;
}

/* Color of the links BEFORE scroll */
.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler-icon {
  color: #fff;
}

/* Color of the links AFTER scroll */
.navbar-scrolled .nav-link,
.navbar-scrolled .navbar-toggler-icon {
  color: #4f4f4f;
}

/* Color of the navbar AFTER scroll */
.navbar-scrolled {
  background-color: #fff;
}

/* An optional height of the navbar AFTER scroll */
.navbar.navbar-scroll.navbar-scrolled {
  padding-top: 5px;
  padding-bottom: 5px;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.line {
  fill: none;
  stroke: v-bind(navLinkColor) !important;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

::marker {
  display: none !important;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
</style>

<i18n>
  {
    "en": {
      "call_btn": "Call Now"
    },
    "es": {
      "call_btn": "LLama Ahora"
    }
  }
  </i18n>


<!-- TODO add language to screens -->
<!-- TODO add recaptcha -->
<!-- TODO add admin panel -->
<!-- TODO add spinner -->
