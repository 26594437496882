<template>
  <picture>
  <source src="https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/nature.webp" type="image/webp" alt="">
</picture>
  
  <div
    id="intro"
    class="bg-image shadow-1-strong"
    style="background-image: url('https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/nature.webp');"
>

<!-- https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/nature.webp -->
    <div class="mask" style="background-color: rgba(18, 12, 20, 0.4)">
      <div
        class="container d-flex align-items-center justify-content-center text-center h-100"
      >
        <div class="text-white">
          <h1 class="mb-3">R4 Enterprises</h1>
          <h5 class="mb-4">{{t('sub')}}</h5>
          <h6 class="mb-4">{{t('mini_sub')}}</h6>
          <div class="d-flex flex-row justify-content-center mt-4">
            <div class="col-6 col-lg-4">
              <a
                class="btn   btn-lg me-2 mt-2 btn-block "
                :class="{'btn-light text-dark ': getState == 'pr', ' btn-outline-light text-white': getState == 'fl'}"
                @click="changeState('pr')"
                role="button"
                
             
                >Puerto Rico</a
              >
            </div>
            <div class="col-6 col-lg-4">
              <a
                class="btn  btn-lg m-2 btn-block ms-2 mt-2"
                :class="{'btn-light text-dark ': getState == 'fl', ' btn-outline-light text-white': getState == 'pr'}"
                @click="changeState('fl')"
              
                role="button"
                >Florida</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MDBAlert
    class="mt-3 text-center"
    color="success"
    v-model="stateChangedAlert"
    ref="hiddenAlertRef"
    autohide
    appendToBody
    :delay="3000"
    position="bottom-center"
    width="320px"
  >
     {{t('location')}} {{getState == 'fl' ? 'Florida' : 'Puerto Rico'}} 
  </MDBAlert>
</template>

<i18n>
  {
    "en": {
      "hello": "hello world!",
      "sub": "RECYCLING / ENVIRONMENTAL / WASTE / INDUSTRIAL",
      "mini_sub": "Taking Steps Forward Into A Cleaner Enviroment.",
      "location": "Location changed to "
    },
    "es": {
      "hello": "hola",
      "sub": "RECICLAJE / AMBIENTAL / RESIDUOS / INDUSTRIALES",
      "mini_sub": "Dando pasos hacia adelante hacia un medio ambiente más limpio.",
      "location": "Ubicación cambiada a "
    }
  }
  </i18n>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { mapGetters } from "vuex";
import {  MDBAlert} from "mdb-vue-ui-kit";


export default{
  setup(){
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    const state = ref(localStorage.getItem('state'))
    const stateChangedAlert = ref(false);
    return{
      t,
      state,
      stateChangedAlert
    }
  },

  components:{
    MDBAlert
  },

   computed:{
    ...mapGetters(["getState"]),
  },
  methods:{
    changeState(state){
      localStorage.setItem('state', state)
      this.state = state
      this.$store.commit('setState', this.state)
      console.log('Stating: ', this.state)
      this.stateChangedAlert = true
    }
  }


}
</script>

<style>
.bg-image {
  height: 100vh;
}

@media (max-width: 992px) {
  .bg-image {
    height: 65vh;
  }
}
/* Height for devices larger than 576px */
/* @media (min-width: 992px) {
  #intro {
    margin-top: -58.59px;
  }
} */

/* .navbar .nav-link {
  color: #fff !important;
} */
</style>
