<template>
  <router-view />
</template>

<style>
.pointer {
  cursor: pointer;
}
.btn-main {
  background-color: v-bind(mainColor) !important;
  color: white;
}

.btn-main:hover {
  color: white !important;
}

.btn-outline-main {
  border: 1px solid v-bind(mainColor);
  color: v-bind(mainColor) !important;
}

.text-main {
  color: v-bind(mainColor);
}
/* .nav-link {
  color: #757575;
}
.nav-link:hover {
  color: v-bind(main-color);
} */
</style>

<script>
import { ref } from "vue";
export default {
  setup() {
    const mainColor = ref("#255C00");
    return {
      mainColor,
    };
  },
};
</script>
