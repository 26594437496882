import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/terms',
        name: 'terms',
        component: () =>
            import ('../views/TermsView')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () =>
            import ('../views/PRivacyView')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router