import { createStore } from 'vuex'

export default createStore({
  state: {
    state: ''
  },
  getters: {
    getState(state){
      return state.state
    }
  },
  mutations: {
    setState(state, payload){
      console.log('Fuck: ', payload)
      state.state = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
