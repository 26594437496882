<template>
  <section>
    <div
      class="bg-image bg-fixed text-center"
      style="background-image: url('https://pub-5663f6a211cc415a8a6ee4e638141325.r2.dev/windmills-1838788_1920.webp'); height: 400px;
      "
    >
      <div class="mask" style="background-color: rgba(156, 39, 176, 0.3)">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="text-white">
            <p class="display-6 mb-5">
              <i class="fas fa-quote-left fa-xs" aria-hidden="true"></i>
              {{t('cta')}}
              <i class="fas fa-quote-right fa-xs" aria-hidden="true"></i>
            </p>
            <textra v-if="false" :data="words" :timer="4" filter="flash" />
            <a
            v-if="getState == 'pr'"
              href="tel:787-675-9600"
              role="button"
              class="btn btn-md pointer btn-main pointer btn-rounded"
            >
              <i class="fa fa-phone me-1"></i> {{t('call_btn')}}
            </a>
            <a
            v-if="getState == 'fl'"
              href="tel:904-677-9600"
              role="button"
              class="btn btn-md pointer btn-main pointer btn-rounded"
            >
              <i class="fa fa-phone me-1"></i> {{t('call_btn')}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n>
  {
    "en": {
      "cta": "Recycle, Reuse, Recover, Reduce",
      "call_btn": "Call Now"

    },
    "es": {
      "cta": "Reciclar, Reutilizar, Recuperar, Reducir",
      "call_btn": "Llama Ahora"
      
    }
  }
  </i18n>

<script>
import { mapGetters } from "vuex";
import Textra from "vue-textra";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
export default {
  components: {
    Textra,
  },
  setup() {
    const words = ref([
      "My text to show",
      "Great news here!",
      "Vue is great",
      "Sample Text",
    ]);
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    return {
      words,
      t
    };
  },
  computed:{
    ...mapGetters(["getState"]),
  }
};
</script>

<style></style>

<!-- TODO text Rotator -->

<!-- Recycle, Reuse, Recover, Reduce -->
